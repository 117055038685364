import React, {Component} from 'react';
import {Grid, withStyles} from "@material-ui/core";
import palette from "../../res/palette";
import spacer from "../../res/spacer";
import fonts from "../../res/fonts";
import StyledLink from "../NavBar/StyledLink";
import HorizontalGradient from "../HorizontalGradient";
import {FormattedMessage} from "react-intl";
import {Language} from "@material-ui/icons";


const styles = () => ({
    wrapper: {
        fontFamily: fonts.nFamily,
        fontSize: fonts.fSize,
        fontStyle: fonts.nStyle,
        borderTop: `${spacer.border}`,
        backgroundColor: palette.white,
        bottom: 0,
        width: '100%',
        height: spacer.footerHeight,
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: palette.white,
    },
    main: {
        width: spacer.pageWidth,
        maxWidth: spacer.pageWidth,
        justifyContent: 'center',
        alignItems: 'center',
    },
    padding: {
        padding: spacer.big,
    },
    grid: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: spacer.medium,
        paddingBottom: spacer.medium,
    },
    list: {
        listStyleType: 'none',
        margin: 0,
        padding: 0,
    },
    listElement: {
        padding: `${spacer.mini} ${spacer.big}`,
    },
    white: {
        color: '100%',
    },
    language: {
        "&:hover": {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
    middleVert: {
        padding: '0 5px',
    },
    langIcon: {
        paddingRight: '5px'
    }
})

class Footer extends Component {
    setLocalLanguage(language) {
        localStorage.setItem('lang', language);
        window.location.reload()
    }

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.wrapper}>
                <HorizontalGradient/>
                <div className={classes.container}>
                    <div className={classes.main}>
                        <div className={classes.padding}>
                            <Grid
                                container
                            >
                                <Grid
                                    className={classes.grid}
                                    item
                                    xl={12}
                                    lg={12}
                                    sm={12}
                                    xs={12}>

                                </Grid>
                                <Grid
                                    className={classes.grid}
                                    item
                                    xl={8}
                                    lg={8}
                                    sm={12}
                                    xs={12}>
                                    © Lütje Steuerungstechnik GmbH & Co. KG. 2025
                                </Grid>
                                <Grid
                                    className={classes.grid}
                                    item
                                    xl={3}
                                    lg={3}
                                    sm={12}
                                    xs={12}>
                                    <ul className={classes.list}>
                                        <li className={classes.listElement}><StyledLink className={classes.white}
                                                                                        to="/References">
                                            <FormattedMessage
                                                id="footer.ref"
                                                defaultMessage="References"
                                            />
                                        </StyledLink>
                                        </li>
                                        <li className={classes.listElement}><StyledLink className={classes.white}
                                                                                        to="/Imprint">
                                            <FormattedMessage
                                                id="footer.imprint"
                                                defaultMessage="Legal Notice"
                                            />
                                        </StyledLink>
                                        </li>
                                        <li className={classes.listElement}><StyledLink className={classes.white}
                                                                                        to="/Terms">
                                            <FormattedMessage
                                                id="footer.terms"
                                                defaultMessage="Data Protection"
                                            />
                                        </StyledLink>
                                        </li>
                                    </ul>
                                </Grid>
                                <Grid
                                    className={classes.grid}
                                    item
                                    xl={1}
                                    lg={1}
                                    sm={12}
                                    xs={12}>
                                    <Language fontSize={"small"} className={classes.langIcon}/>
                                    <div className={classes.language} onClick={() => {this.setLocalLanguage('de')}}>DE</div>
                                    <div className={classes.middleVert}>|</div> <div className={classes.language} onClick={() => {this.setLocalLanguage('en')}}>EN</div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles,
    {
        withTheme: true
    }
)(Footer);